import React, { useEffect } from "react";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
//redux
import store from "./redux/store";
import { Provider } from "react-redux";
//components
import ScrollToTop from './components/ScrollToTop';
//loader
import SimpleBackdrop from "./components/spinner";
//Alert
import Alert from "./components/alert";
//razor
import { loadScript } from "./utils/common";
import { Staging, Production } from "./utils/paytm";

import "./App.css";

function App() {

  useEffect(() => {

    const NODE_ENV = process.env.NODE_ENV
    let url = Staging
    let MID = process.env.REACT_APP_MID
    if (NODE_ENV === 'production') {
      url = Production
      MID = process.env.REACT_APP_MID
    }

    // loadScript("https://checkout.razorpay.com/v1/checkout.js");
    loadScript(`${url}/merchantpgpui/checkoutjs/merchants/${MID}.js`);
  }, [])

  return (
    <>
      <Provider store={store}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <SimpleBackdrop/>
          <Alert/>
          <Router />
        </ThemeConfig>
      </Provider>
    </>
  );
}

export default App;
